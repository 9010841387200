import * as React from 'react'
import Layout from '../../components/Layout'
import LinkBar from '../../components/LinkBar'
import BlockLinkPartial from "../../components/BlockLink/BlockLinkPartial";
import TopImageHeading from "../../components/TopImageHeading/TopImageHeading";

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import {
    middleCardImages,
    middleCardText,
    middleCardImage,
    middleCardWrapper
} from "../../components/styles/titlepage.module.css";
import {
    topImageSectionImage, blockLinkBox
} from "../../components/styles/mainpages.module.css"

//Global stylesheet
import "../../components/styles/global.css"

const Activities = () => {
    return(
        <Layout pageTitle={"activities"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Things to do in Bangbiangyang | Ministry of Tourism Official Website</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="Take a leap of faith from a kilometre-high statue at dawn. Have a bite of sweet Deprexxizawan bread for lunch.
                Venture deep into an ancient system of caverns in the afternoon, and finish off with a two-thousand-year-old dish for dinner. Or do something else.
                Explore countless activities at the official site for Tourism Bangbiangyang."/>

                <meta property="og:title" content="Places to go in Bangbiangyang | Ministry of Tourism Official Website"/>
                <meta property="og:description" content="Take a leap of faith from a kilometre-high statue at dawn. Have a bite of sweet Deprexxizawan bread for lunch.
                Venture deep into an ancient system of caverns in the afternoon, and finish off with a two-thousand-year-old dish for dinner. Or do something else.
                Explore countless activities at the official site for Tourism Bangbiangyang."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/activities"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="/activities">Things to do</Link>
            </LinkBar>

            <TopImageHeading title="Discover activities and events">
                <StaticImage className={topImageSectionImage} src="https://upload.wikimedia.org/wikipedia/commons/8/8a/20181110_Fushimi_Inari_Torii_12.jpg" alt="."/>
            </TopImageHeading>

            <div className="midContentWrapper">
                <div className={middleCardWrapper}>
                    <div className={middleCardText}>
                        <h5 data-sal="slide-up">
                            A common local folk tale tells the story of a long-gone god, whose parting gift was the formula to create paint from local fauna.
                        </h5>
                        <p data-sal="slide-up">
                            The artistic, cultural, and spiritual history of Bangbiangyang descends from origins still far too ancient for modern understanding. Regardless, with many
                            centuries of human development, the unique cuisines and renowned adventures that have become synonymous with our country are open to visitors from all walks
                            of life. Whether it's fast-paced avalanche-assisted skiing or a quiet sit-in on a local sacrifice, it's right here.
                        </p>

                        <h3 className="genericHeading" data-sal="slide-up">
                            Popular activities
                        </h3>

                        <div className={blockLinkBox}>
                            <BlockLinkPartial title="Yes" destination="./y">
                                Ayayayayayay
                            </BlockLinkPartial>
                            <BlockLinkPartial title="No" destination="./y">
                                ayayayayayya
                            </BlockLinkPartial>
                        </div>
                    </div>


                    <div className={middleCardImage}>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Activities